import Mixins from "../../Mixins.js";
import Moment from "moment";

export default {
  name: "EditMortgageAccount",
  mixins: [Mixins],
  data() {
    return {
      identity: {
        cifId: "",
        mortgageAccountId: "",
      },
      property: {
        animation: {
          editMortgageAccount: {
            isLoading: false,
          },
        },
      },
      identifier: {
        cifNumber: "",
        cifCategoryName: "",
        cifIdName: "",
        cifOfficeName: "",
        timeType: "",
        mortgageRegisterNumber: "",
        brutoTotal: 0,
        netWeightTotal: 0,
        slteTotal: 0,
        estimatePriceTotal: 0,
        rentPriceTotal: 0,
        maxLoanTotal: 0,
      },
      dataForm: {
        referalOfficeId: "",
        cifId: "",
        akadNumber: "",
        mortgageAccountNumber: "",
        loanAkadTypeId: "",
        loanId: "",
        akadDate: "",
        effectiveDate: "",
        timePeriod: 0,
        defiationMemoNumber: "",
        discountRentPercent: 0,
        dueDate: "",
        auctionDate: "",
        purposeOpenAccountId: "",
        estimatePrice: 0,
        accountOfficerCollectorId: "",
        accountOfficerHandleId: "",
        accountOfficerPromoId: "",
        dropingAccountId: "",
        feeRentPercent: 0,
        feeRentNominal: 0,
        feeAdmin: 0,
        mortgageRegisterId: "",
        feeMaterai: 0,
        feeTotal: 0,
        nominalMortgage: 0,
        nominalTotal: 0,
        suggestedNominalTotal: 0,
        sltePrice: 0,
        sltePriceInput: 0,
        tmortgageCollateralPreciousMetalRequestDtos: [],
        finalTmortgageCollateralPreciousMetalRequestDtos: [],
        createdByUserCode: "",
        createdByUserName: "",
        createdDate: "",
        updatedDate: "",
        updatedByUserCode: "",
        updatedByUserName: "",
        authorizedDate: "",
        authorizedByUserCode: "",
        authorizedByUserName: "",
      },
      options: {
        loanAkadType: [],
        referalOffice: [],
        loan: [],
        loanByAkad: [],
        purposeOpenAccount: [],
        collector: [],
        aoHandle: [],
        aoPromo: [],
        timePeriod: [],
        mortgageRegisterNumber: [],
        dropingAccount: [],
        mortgage: [],
      },
      table: {
        data: {
          tmortgageCollateralPreciousMetalRequestDtos: [],
        },
      },
    };
  },
  methods: {
    deleteMortgageCollateralPreciousMetal(props) {
      const findingUnSelected = this.table.data.tmortgageCollateralPreciousMetalRequestDtos.filter(
        (i) => i.id !== props.row.id
      );
      this.dataForm.tmortgageCollateralPreciousMetalRequestDtos.map((data) => {
        if (
          data.mortgageAccountCollateralId ===
          props.row.mortgageAccountCollateralId
        ) {
          data.deleted = true;
        }
      });
      this.table.data.tmortgageCollateralPreciousMetalRequestDtos = findingUnSelected;
      this.calculateTotalBrutoFooter();
      this.calculateTotalNetWeightFooter();
      this.calculateTotalSlteFooter();
      this.calculateTotalEstimatePriceFooter();
      this.calculateTotalRentPriceFooter();
      this.calculateTotalMaxLoanFooter();
    },
    addMortgageCollateralPreciousMetal() {
      const payload = {
        id: Math.random()
          .toString(36)
          .substr(2, 9),
        mortgage: {
          mortgageId: "",
          estimatePrice: 0,
          rentPricePercent: 0,
          sltePrice: 0,
          sltePriceInput: 0,
          rmortgageMetalType: {
            metalTypeName: "",
          },
          rmortgageCarat: {
            mortgageCaratName: "",
          },
          maxLoanPercent: 0,
        },
        quantity: 0,
        bruto: 0,
        netWeight: 0,
        totalEstimate: 0,
        rentPrice: 0,
        maxLoan: 0,
        rloanCollateralTypeId: this.fetchAppSession(
          "@vue-session/config-bundle"
        ).VUE_APP_LOAN_COLLATERAL_TYPE_GOLD_JEWERLY,
        rloanCollateralBindTypeId: this.fetchAppSession(
          "@vue-session/config-bundle"
        ).VUE_APP_LOAN_COLLATERAL_BIND_TYPE_GADAI_ID,
        mortgageAccountCollateralId: "",
        rloanCollateralCategoryId: this.fetchAppSession(
          "@vue-session/config-bundle"
        ).VUE_APP_LOAN_COLLATERAL_CATEGORY_PRECIOUS_METAL,
      };
      this.table.data.tmortgageCollateralPreciousMetalRequestDtos.push(payload);
    },
    changeEffectiveDate() {
      this.dataForm.dueDate = Moment(new Date(this.dataForm.effectiveDate))
        .add(this.dataForm.timePeriod, "M")
        .format();
      this.dataForm.auctionDate = Moment(new Date(this.dataForm.dueDate))
        .add(10, "days")
        .format();
    },
    changeOptionsMortgageRegisterNumber() {
      this.options.mortgageRegisterNumber.map((index) => {
        if (index.value === this.dataForm.mortgageRegisterId) {
          this.identifier.mortgageRegisterNumber = index.mortgageRegisterNumber;
        }
      });
    },
    getIdentityFromSession() {
      var params = this.$route.params.id;
      const getTokenFromSession = this.decryptBASE64(params);
      this.identity.mortgageAccountId = getTokenFromSession;
      this.$store.dispatch(
        "SET_IDENTIFIER_MORTGAGE_ACCOUNT_ID",
        getTokenFromSession
      );
      this.MIXINS_METHOD_getRefferenceMortgageAccount();
    },
    async getReferencePurposeOpenAccount() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_TRANSACTIONS", {
          url: "purpose-open-account",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.purposeOpenAccountName;
            const value = index.purposeOpenAccountId;
            this.options.purposeOpenAccount.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceMortgage() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_LOAN",
          {
            url: "mortgage",
            params: {
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((data) => {
            const text = `${data.mortgageCode} - ${data.mortgageName}`;
            const value = data.mortgageId;
            this.options.mortgage.push({
              text,
              value,
              mortgage: data,
            });
          });
        }
      } catch (error) {}
    },
    changeOptionsMortgage(props) {
      const findingMortgage = this.options.mortgage.find(
        (i) => i.value === props.row.mortgage.mortgageId
      );
      this.table.data.tmortgageCollateralPreciousMetalRequestDtos.map(
        (data) => {
          if (data.id === props.row.id) {
            data.mortgage = findingMortgage.mortgage;
            data.mortgage.sltePriceInput = data.mortgage.sltePrice;
            this.calculateTotalEstimatePerRow(props);
            this.calculateRentPricePerRow(props, "per-row");
            this.calculateMaxLoanPerRow(props, "per-row");
            this.calculateTotalSlteFooter();
          }
        }
      );
    },
    calculateTotalEstimatePerRow(props) {
      this.table.data.tmortgageCollateralPreciousMetalRequestDtos.map(
        (data) => {
          if (data.id === props.row.id) {
            data.totalEstimate =
              ((data.netWeight *
                parseFloat(data.quantity) *
                data.mortgage.sltePriceInput) /
                data.mortgage.maxLoanPercent) *
              100;
            this.calculateRentPricePerRow(props, "per-row");
            this.calculateMaxLoanPerRow(props, "per-row");
            this.calculateTotalNetWeightFooter();
            this.calculateTotalEstimatePriceFooter();
            this.calculateTotalSlteFooter();
          }
        }
      );
    },
    async getReferenceOfficeReferal() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "office/office-parrent",
          payload: {
            isReferenced: false,
          },
        });
        if (resp.data.code === "SUCCESS") {
          this.options.referalOffice = resp.data.data;
          this.options.referalOffice.unshift({
            officeName: "-- Pilih --",
            officeId: "",
          });
        }
      } catch (error) {}
    },
    calculateRentPricePerRow(props, type) {
      this.table.data.tmortgageCollateralPreciousMetalRequestDtos.map(
        (data) => {
          switch (type) {
            case "per-row":
              if (data.id === props.row.id) {
                let totalEstimateXTimeperiod =
                  data.totalEstimate * this.dataForm.timePeriod;
                let rentPricePercent =
                  (data.mortgage.rentPricePercent * 10 -
                    this.dataForm.discountRentPercent * 10) /
                  10 /
                  100;
                data.rentPrice = totalEstimateXTimeperiod * rentPricePercent;
              }
              break;
            case "all-row": {
              let totalEstimateXTimeperiod =
                data.totalEstimate * this.dataForm.timePeriod;
              let rentPricePercent =
                (data.mortgage.rentPricePercent * 10 -
                  this.dataForm.discountRentPercent * 10) /
                10 /
                100;
              data.rentPrice = totalEstimateXTimeperiod * rentPricePercent;
              break;
            }
            default:
              break;
          }
          this.calculateTotalRentPriceFooter();
        }
      );
    },
    calculateMaxLoanPerRow(props, type) {
      this.table.data.tmortgageCollateralPreciousMetalRequestDtos.map(
        (data) => {
          switch (type) {
            case "per-row":
              if (data.id === props.row.id) {
                data.maxLoan =
                  data.totalEstimate * (data.mortgage.maxLoanPercent / 100);
              }
              break;
            case "all-row":
              data.maxLoan =
                data.totalEstimate * (data.mortgage.maxLoanPercent / 100);
              100;
              break;
            default:
              break;
          }
          this.calculateTotalMaxLoanFooter();
        }
      );
    },
    async getReferenceLoan() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_LOAN",
          {
            url: "loan/simple-list",
            params: {
              loanName: "",
              loanCode: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          Promise.all(
            resp.data.data.content.map((index) => {
              const text = `${index.loanName} - ${index.loanCode}`;
              const value = index.loanId;
              const marginCalculate = index.loanMarginCalculateCode;
              const loanAkadTypeAcronym = index.loanAkadTypeAcronym;
              const loanCode = index.loanCode;
              const loanAkadTypeId = index.loanAkadTypeId;
              this.options.loan.push({
                text,
                value,
                marginCalculate,
                loanAkadTypeId,
                loanAkadTypeAcronym,
                loanCode,
              });
            })
          ).then(() => {
            this.getMortgageAccountById();
          });
        }
      } catch (error) {}
    },
    async getMortgageAccountById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.routeToPageListMortgageAccount();
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          reqUrl: "mortgage-account/" + this.identity.mortgageAccountId,
          endPoint: "loan",
        });
        if (resp.data.code === "SUCCESS") {
          this.dataForm.mortgageAccountNumber =
            resp.data.data.mortgageAccountNumber;
          this.identity.cifId = resp.data.data.mcif
            ? resp.data.data.mcif.cifId
            : "";
          this.getSavingAccountByCifId();
          this.identifier.cifNumber = resp.data.data.mcif
            ? resp.data.data.mcif.cifNumber
            : "";
          this.identifier.cifCategoryName = resp.data.data.mcif
            ? resp.data.data.mcif.rcifCategoryByCifCategoryId
              ? resp.data.data.mcif.rcifCategoryByCifCategoryId.cifCategoryId
              : ""
            : "";
          this.identifier.cifIdName = resp.data.data.mcif
            ? resp.data.data.mcif.cifIdName
            : "";
          this.identifier.cifOfficeName = resp.data.data.mcif
            ? resp.data.data.mcif.moffice
              ? resp.data.data.mcif.moffice.officeName
              : ""
            : "";
          this.dataForm.cifId = resp.data.data.mcif
            ? resp.data.data.mcif.cifId
            : "";
          this.dataForm.akadNumber = resp.data.data.akadNumber;
          this.dataForm.loanAkadTypeId = resp.data.data.rloanAkadType
            ? resp.data.data.rloanAkadType.loanAkadTypeId
            : "";
          this.changeTypeAkadMortgageAccount();
          this.dataForm.loanId = resp.data.data.mloan
            ? resp.data.data.mloan.loanId
            : "";
          this.dataForm.referalOfficeId = resp.data.data.referalOffice
            ? resp.data.data.referalOffice.officeId
            : "";
          this.dataForm.createdByUserCode = resp.data.data.createdByUserCode;
          this.dataForm.createdByUserName = resp.data.data.createdByUserName;
          this.dataForm.createdDate = resp.data.data.createdDate;
          this.dataForm.updatedDate = resp.data.data.updatedDate;
          this.dataForm.updatedByUserCode = resp.data.data.updatedByUserCode;
          this.dataForm.updatedByUserName = resp.data.data.updatedByUserName;
          this.dataForm.authorizedDate = resp.data.data.authorizedDate;
          this.dataForm.authorizedByUserCode =
            resp.data.data.authorizedByUserCode;
          this.dataForm.authorizedByUserName =
            resp.data.data.authorizedByUserName;
          this.dataForm.timePeriod = resp.data.data.timePeriod;
          this.dataForm.mortgageRegisterId = resp.data.data.mortgageRegisterId
            ? resp.data.data.mortgageRegisterId.mortgageRegisterId
            : "";
          this.dataForm.defiationMemoNumber =
            resp.data.data.defiationMemoNumber;
          this.dataForm.discountRentPercent =
            resp.data.data.discountRentPercent;
          this.identifier.mortgageRegisterNumber = resp.data.data
            .mortgageRegisterId
            ? resp.data.data.mortgageRegisterId.mortgageRegisterNumber
            : "";
          this.dataForm.akadDate = resp.data.data.akadDate;
          this.dataForm.effectiveDate = resp.data.data.effectiveDate;
          this.dataForm.dueDate = resp.data.data.dueDate;
          this.dataForm.auctionDate = resp.data.data.auctionDate;
          this.dataForm.purposeOpenAccountId = resp.data.data
            .rpurposeOpenAccount
            ? resp.data.data.rpurposeOpenAccount.purposeOpenAccountId
            : "";
          this.dataForm.estimatePrice = resp.data.data.estimatePrice;
          this.dataForm.accountOfficerCollectorId =
            resp.data.data.accountOfficerCollectorId;
          this.dataForm.accountOfficerHandleId =
            resp.data.data.accountOfficerHandleId;
          this.dataForm.accountOfficerPromoId =
            resp.data.data.accountOfficerPromoId;
          this.dataForm.dropingAccountId = resp.data.data.dropingAccountId
            ? resp.data.data.dropingAccountId.savingAccountId
            : "";
          this.dataForm.feeRentPercent = resp.data.data.feeRentPercent;
          this.dataForm.feeRentNominal = resp.data.data.feeRentNominal;
          this.dataForm.feeAdmin = resp.data.data.feeAdmin;
          this.dataForm.feeMaterai = resp.data.data.feeMaterai;
          this.dataForm.feeTotal = resp.data.data.feeTotal;
          this.dataForm.nominalMortgage = resp.data.data.nominalMortgage;
          this.dataForm.nominalTotal = resp.data.data.nominalTotal;
          this.dataForm.suggestedNominalTotal = resp.data.data.nominalTotal;
          this.dataForm.tmortgageCollateralPreciousMetalRequestDtos =
            resp.data.data.tmortgageCollateralPreciousMetal;
          if (resp.data.data.tmortgageCollateralPreciousMetal) {
            this.setupTmortgageCollateralPreciousMetalRequestDtosTable(
              resp.data.data.tmortgageCollateralPreciousMetal
            );
          }
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.routeToPageListMortgageAccount(),
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
          onConfirm: () => this.routeToPageListMortgageAccount(),
        });
      }
    },
    setupTmortgageCollateralPreciousMetalRequestDtosTable(
      tmortgageCollateralPreciousMetal
    ) {
      tmortgageCollateralPreciousMetal.map((data) => {
        const payload = {
          id: Math.random()
            .toString(36)
            .substr(2, 9),
          mortgage: {
            mortgageId: data.mmortgage ? data.mmortgage.mortgageId : "",
            estimatePrice: data.mmortgage
              ? data.mmortgage.estimatePrice
                ? data.mmortgage.estimatePrice
                : 0
              : 0,
            rentPricePercent: data.mmortgage
              ? data.mmortgage.rentPricePercent
                ? data.mmortgage.rentPricePercent
                : 0
              : 0,
            sltePrice: data.mmortgage
              ? data.mmortgage.sltePrice
                ? data.mmortgage.sltePrice
                : 0
              : 0,
            sltePriceInput: data.sltePrice ? data.sltePrice : 0,
            rmortgageMetalType: {
              metalTypeName: data.mmortgage
                ? data.mmortgage.rmortgageMetalType
                  ? data.mmortgage.rmortgageMetalType.metalTypeName
                  : "-"
                : "-",
            },
            rmortgageCarat: {
              mortgageCaratName: data.mmortgage
                ? data.mmortgage.rmortgageCarat
                  ? data.mmortgage.rmortgageCarat.mortgageCaratName
                  : "-"
                : "-",
            },
            maxLoanPercent: data.mmortgage
              ? data.mmortgage.maxLoanPercent
                ? data.mmortgage.maxLoanPercent
                : 0
              : 0,
          },
          quantity: data.quantity ? data.quantity : 0,
          bruto: data.bruto ? data.bruto : 0,
          netWeight: data.netWeight ? data.netWeight : 0,
          totalEstimate: data.estimateValue ? data.estimateValue : 0,
          rentPrice:
            data.estimateValue *
            this.dataForm.timePeriod *
            (data.mmortgage.rentPricePercent / 100),
          maxLoan: data.estimateValue * (data.mmortgage.maxLoanPercent / 100),
          mortgageAccountCollateralId: data.mortgageAccountCollateralId,
          rloanCollateralTypeId: data.rloanCollateralType
            ? data.rloanCollateralType.collateralTypeId
            : "",
          rloanCollateralBindTypeId: data.rloanCollateralBindType
            ? data.rloanCollateralBindType.collateralBindTypeId
            : "",
          rloanCollateralCategoryId: data.rloanCollateralCategory
            ? data.rloanCollateralCategory.rloanCollateralCategoryId
            : "",
        };
        this.table.data.tmortgageCollateralPreciousMetalRequestDtos.push(
          payload
        );
        this.calculateTotalBrutoFooter();
        this.calculateTotalNetWeightFooter();
        this.calculateTotalSlteFooter();
        this.calculateTotalEstimatePriceFooter("autotable");
        this.calculateTotalRentPriceFooter("autotable");
        this.calculateTotalMaxLoanFooter("autotable");
      });
    },
    async getSavingAccountByCifId() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.routeToPageListMortgageAccount();
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl: "saving-account/cif-id/" + this.identity.cifId,
          payload: {
            isCrossOffice: true,
          },
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = `${index.mcif.cifIdName} - ${index.accountNumber} - ${index.msaving.savingName}`;
            const value = index.savingAccountId;
            this.options.dropingAccount.push({ text, value });
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    // async getReferenceCollector() {
    //   try {
    //     const resp = await this.$store.dispatch(
    //       "GET_REFERENCE_FROM_USER_MANAGEMENT",
    //       {
    //         url: "v2/user/active-user",
    //         params: {
    //           page: 0,
    //         },
    //       }
    //     );
    //     if (resp.data.code === "SUCCESS") {
    //       resp.data.data.content.map((index) => {
    //         const text = `${index.profileSurename} - ${index.userNik}`;
    //         const value = index.userId;
    //         this.options.collector.push({ text, value });
    //       });
    //     }
    //   } catch (error) {}
    // },
    async getReferenceAccountOfficer() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "parameter",
          reqUrl: "account-officer/reference",
          payload: {
            jobTitle: "",
          },
        });
        console.log("AO => ", resp);
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = `${index.accountOfficerName} - ${index.accountOfficerCode}`;
            const value = index.accountOfficerId;
            this.options.aoPromo.push({ text, value });
            this.options.aoHandle.push({ text, value });
            this.options.collector.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async getReferenceMortgageRegisterNumber() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_LOAN",
          {
            url: "mortgage-register-number/inventory/simple-list",
            params: {
              mortgageRegisterNumber: "",
              page: 0,
            },
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((index) => {
            const text = `${index.mortgageRegisterNumber}`;
            const value = index.mortgageRegisterId;
            const mortgageRegisterNumber = index.mortgageRegisterNumber;
            this.options.mortgageRegisterNumber.push({
              text,
              mortgageRegisterNumber,
              value,
            });
          });
        }
      } catch (error) {}
    },
    routeToPageListMortgageAccount() {
      this.$router.push("list");
    },
    async getCifById() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        this.routeToPageListMortgageAccount();
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "transaction",
          reqUrl: "cif/" + this.identity.cifId,
        });
        if (resp.data.code === "SUCCESS") {
          this.identifier.cifNumber = resp.data.data.cifNumber;
          this.identifier.cifCategoryName =
            resp.data.data.categoryId.cifCategoryName;
          this.identifier.cifIdName = resp.data.data.cifIdName;
          this.identifier.cifOfficeName = resp.data.data.officeId.officeName;
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
            onConfirm: () => this.routeToPageListMortgageAccount(),
          });
        }
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
          onConfirm: () => this.routeToPageListMortgageAccount(),
        });
      }
    },
    changeTypeAkadMortgageAccount() {
      const filterLoanByAkad = this.options.loan.filter(
        (index) => index.loanAkadTypeId === this.dataForm.loanAkadTypeId
      );
      this.dataForm.loanId = "";
      this.options.loanByAkad = [
        { value: "", text: "-- Pilih --" },
        ...filterLoanByAkad,
      ];
    },
    generateCollateralPayloadPreciousMetal() {
      this.dataForm.tmortgageCollateralPreciousMetalRequestDtos.map((index) => {
        if (index.deleted === true) {
          var findAvailibilityIndex = this.dataForm.finalTmortgageCollateralPreciousMetalRequestDtos.filter(
            (i) =>
              i.mortgageAccountCollateralId ===
              index.mortgageAccountCollateralId
          );

          if (findAvailibilityIndex.length === 0) {
            var payload = {
              mortgageAccountCollateralId: index.mortgageAccountCollateralId,
              quantity: index.quantity,
              netWeight: index.netWeight,
              estimateValue: index.estimateValue,
              bruto: index.bruto,
              rloanCollateralTypeId: index.rloanCollateralType
                ? index.rloanCollateralType.collateralTypeId
                : "",
              rloanCollateralBindTypeId: index.rloanCollateralBindType
                ? index.rloanCollateralBindType.collateralBindTypeId
                : "",
              rloanCollateralCategoryId: index.rloanCollateralCategory
                ? index.rloanCollateralCategory.rloanCollateralCategoryId
                : "",
              mortgageId: index.mmortgage ? index.mmortgage.mortgageId : "",
              deleted: true,
            };
            this.dataForm.finalTmortgageCollateralPreciousMetalRequestDtos.push(
              payload
            );
          }
        }
      });
      this.table.data.tmortgageCollateralPreciousMetalRequestDtos.map(
        (data) => {
          var payload = {
            mortgageAccountCollateralId: data.mortgageAccountCollateralId,
            quantity: data.quantity,
            netWeight: data.netWeight,
            estimateValue: data.totalEstimate,
            sltePrice: data.mortgage.sltePriceInput,
            bruto: data.bruto,
            rloanCollateralTypeId: data.rloanCollateralTypeId,
            rloanCollateralBindTypeId: data.rloanCollateralBindTypeId,
            rloanCollateralCategoryId: data.rloanCollateralCategoryId,
            mortgageId: data.mortgage.mortgageId,
            deleted: false,
          };
          this.dataForm.finalTmortgageCollateralPreciousMetalRequestDtos.push(
            payload
          );
        }
      );
    },
    async editMortgageAccount() {
      this.generateCollateralPayloadPreciousMetal();
      var payload = {
        cifId: this.identity.cifId,
        loanAkadTypeId: this.dataForm.loanAkadTypeId,
        loanId: this.dataForm.loanId,
        akadDate: this.dataForm.akadDate,
        effectiveDate: this.dataForm.effectiveDate,
        timePeriod: this.dataForm.timePeriod,
        dueDate: this.dataForm.dueDate,
        auctionDate: this.dataForm.auctionDate,
        purposeOpenAccountId: this.dataForm.purposeOpenAccountId,
        estimatePrice: this.dataForm.estimatePrice,
        accountOfficerCollectorId: this.dataForm.accountOfficerCollectorId,
        accountOfficerHandleId: this.dataForm.accountOfficerHandleId,
        accountOfficerPromoId: this.dataForm.accountOfficerPromoId,
        mortgageRegisterId: this.dataForm.mortgageRegisterId,
        dropingAccountId: this.dataForm.dropingAccountId,
        feeRentPercent: this.dataForm.feeRentPercent,
        feeRentNominal: this.dataForm.feeRentNominal,
        feeAdmin: this.dataForm.feeAdmin,
        feeMaterai: this.dataForm.feeMaterai,
        feeTotal: this.dataForm.feeTotal,
        nominalMortgage: this.dataForm.nominalMortgage,
        nominalTotal: this.dataForm.nominalTotal,
        defiationMemoNumber: this.dataForm.defiationMemoNumber,
        discountRentPercent: this.dataForm.discountRentPercent,
        sltePrice: this.dataForm.sltePrice,
        tmortgageCollateralPreciousMetalRequestDtos: this.dataForm
          .finalTmortgageCollateralPreciousMetalRequestDtos,
        referalOfficeId: this.dataForm.referalOfficeId,
      };
      this.dataForm.finalTmortgageCollateralPreciousMetalRequestDtos = [];
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Edit Data Ini ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.editMortgageAccount.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "loan",
                reqUrl: "mortgage-account/" + this.identity.mortgageAccountId,
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.toast.open(this.$NotificationUtils.success);
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.editMortgageAccount.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    async getReferenceTimePeriode() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_LOAN", {
          url: "time-period",
        });
        if (resp.data.code === "SUCCESS") {
          this.options.timePeriod = [{ value: 0, text: "-- Pilih --" }];
          resp.data.data.map((data) => {
            const value = data.timePeriodName;
            const text = `${data.timePeriodName} ${data.timeType}`;
            this.options.timePeriod.push({ text, value });
          });
        }
      } catch (error) {}
    },
    changeTimePeriod() {
      this.changeEffectiveDate();
      this.calculateRentPricePerRow("", "all-row");
      this.calculateTotalMaxLoanFooter();
    },
    calculateTotalBrutoFooter() {
      this.identifier.brutoTotal = 0;
      this.table.data.tmortgageCollateralPreciousMetalRequestDtos.map(
        (data) => {
          this.identifier.brutoTotal =
            this.identifier.brutoTotal + parseFloat(data.bruto);
        }
      );
    },
    calculateTotalNetWeightFooter() {
      this.identifier.netWeightTotal = 0;
      this.table.data.tmortgageCollateralPreciousMetalRequestDtos.map(
        (data) => {
          this.identifier.netWeightTotal =
            this.identifier.netWeightTotal + parseFloat(data.netWeight);
        }
      );
    },
    calculateTotalSlteFooter(props) {
      this.identifier.slteTotal = 0;
      this.table.data.tmortgageCollateralPreciousMetalRequestDtos.map(
        (data) => {
          var sltePrice = data.mortgage ? data.mortgage.sltePriceInput : 0;
          this.identifier.slteTotal =
            this.identifier.slteTotal + parseFloat(sltePrice);
          // this.calculateTotalEstimatePerRow(props);
        }
      );
    },
    calculateTotalEstimatePriceFooter(type) {
      if (type !== "autotable") {
        this.dataForm.estimatePrice = 0;
      }
      this.identifier.estimatePriceTotal = 0;
      this.table.data.tmortgageCollateralPreciousMetalRequestDtos.map(
        (data) => {
          var totalEstimate = data.totalEstimate;
          this.identifier.estimatePriceTotal =
            this.identifier.estimatePriceTotal + totalEstimate;
          if (type !== "autotable") {
            this.dataForm.estimatePrice = this.identifier.estimatePriceTotal;
          }
        }
      );
    },
    calculateTotalRentPriceFooter(type) {
      this.identifier.rentPriceTotal = 0;
      this.table.data.tmortgageCollateralPreciousMetalRequestDtos.map(
        (data) => {
          var rentPrice = data.rentPrice;
          this.identifier.rentPriceTotal =
            this.identifier.rentPriceTotal + rentPrice;
        }
      );
      if (type !== "autotable") {
        this.dataForm.feeRentNominal = this.identifier.rentPriceTotal;
        this.calculateFeeTotal();
      }
    },
    calculateTotalMaxLoanFooter(type) {
      this.identifier.maxLoanTotal = 0;
      this.table.data.tmortgageCollateralPreciousMetalRequestDtos.map(
        (data) => {
          var maxLoan = data.maxLoan;
          this.identifier.maxLoanTotal = this.identifier.maxLoanTotal + maxLoan;
        }
      );
      if (type !== "autotable") {
        this.dataForm.nominalMortgage = this.identifier.maxLoanTotal;
        this.calculateNominalTotal();
        this.dataForm.feeRentPercent = 0;
        if (
          this.identifier.rentPriceTotal !== 0 &&
          this.identifier.maxLoanTotal
        ) {
          this.dataForm.feeRentPercent =
            (this.identifier.rentPriceTotal / this.identifier.maxLoanTotal) *
            100;
        }
        this.calculateFeeTotal();
      }
    },
    calculateFeeTotal() {
      this.dataForm.feeTotal =
        this.dataForm.feeMaterai +
        this.dataForm.feeAdmin +
        this.dataForm.feeRentNominal;
    },
    calculateNominalTotal() {
      this.dataForm.suggestedNominalTotal =
        this.dataForm.nominalMortgage -
        this.dataForm.feeRentNominal -
        this.dataForm.feeAdmin -
        this.dataForm.feeMaterai;
    },
    async getReferenceLoanAkadType() {
      try {
        const resp = await this.$store.dispatch("GET_REFERENCE_LOAN", {
          url: "loan-akad-type",
        });
        if (resp.data.code === "SUCCESS") {
          resp.data.data.map((index) => {
            const text = index.loanAkadTypeName;
            const value = index.loanAkadTypeId;
            this.options.loanAkadType.push({ text, value });
          });
        }
      } catch (error) {}
    },
  },
  mounted() {
    console.log("mounted");
    this.getIdentityFromSession();
    this.getReferenceMortgageRegisterNumber();
    this.getReferenceLoanAkadType();
    this.getReferenceLoan();
    // this.getReferenceCollector();
    this.getReferenceAccountOfficer();
    this.getReferencePurposeOpenAccount();
    this.getReferenceTimePeriode();
    this.getReferenceMortgage();
    this.getReferenceOfficeReferal();
  },
};
