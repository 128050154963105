import EditMortgageAccount from "./mortage-account";
import EditMortgageCollateralPreciousMetal from "./mortage-collateral-precious-metal";
import EditMortgagePhotoUpload from "./mortage-photo-upload";
import EditMortgageDocumentChecklist from "./mortage-document-checklist";
import Mixins from "../Mixins.js";

export default {
  name: "EditTMortgageAccount",
  mixins: [Mixins],
  components: {
    EditMortgageAccount,
    EditMortgageCollateralPreciousMetal,
    EditMortgagePhotoUpload,
    EditMortgageDocumentChecklist,
  },
  destroyed() {
    this.$store.dispatch("CLEAR_OPTIONS_MORTGAGE_ACCOUNT");
  },
  methods: {
    centralBackToList() {
      this.$router.push("/loan/mortgage-account");
    },
  },
};
