import Mixins from "../../Mixins.js";
import { create } from "ipfs-http-client";

export default {
  name: "EditMortgageDocumentChecklist",
  mixins: [Mixins],

  data() {
    return {
      uploadProgressPanel: {
        message: "",
        open: false,
        done: false,
        uploaded: [],
        reset: function() {
          this.message = "";
          this.open = false;
          this.done = false;
          this.uploaded = [];
        },
      },
      property: {
        modal: {
          formInputCollateralFile: false,
          formInputCollateralBindFile: false,
        },
        animation: {
          step6: {
            submitDocumentChecklist: {
              isLoading: false,
            },
          },
        },
      },
      identifier: {
        documentChecklist: {
          identifierEdit: false,
        },
      },
      dataForm: {
        step6: {
          mortgageAccountId: "",
          collateralExist: false,
          collateralBindExist: false,
          collateralFiles: [],
          collateralBindFiles: [],
        },
      },
    };
  },
  methods: {
    onSubmitModalUploadCollateralFile(payload) {
      this.dataForm.step6.collateralFiles.push(payload);
      console.log(payload);
    },
    onDeleteCollateralFile(index) {
      this.dataForm.step6.collateralFiles.splice(index, 1);
    },
    onSubmitModalUploadCollateralBindFile(payload) {
      this.dataForm.step6.collateralBindFiles.push(payload);
    },
    onDeleteCollateralBindFile(index) {
      this.dataForm.step6.collateralBindFiles.splice(index, 1);
    },
    onDownloadFiles(item) {
      var file = new Blob([item.documentFile], {
        type: item.documentFile.type,
      });
      var a = document.createElement("a"),
        url = URL.createObjectURL(file);
      a.href = url;
      a.download = item.documentFile.name;
      document.body.appendChild(a);
      a.click();
      setTimeout(function() {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    },
    async getMortgageDocumentChecklistByMortgageAccountId() {
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "loan",
          reqUrl:
            "mortgage-document-checklist/mortgage-account-id/" +
            this.mixin_data_identifier_mortgage_account_id,
        });
        if (resp.data.code === "SUCCESS") {
          var response = resp.data.data;
          var collateralFilesConverted = [];
          var collateralBindFilesConverted = [];
          do {
            var resultConvertCollateralFiles = await this.convertHashToFile(
              response.collateralFiles[collateralFilesConverted.length]
            );
            collateralFilesConverted.push(resultConvertCollateralFiles);
          } while (
            collateralFilesConverted.length !== response.collateralFiles.length
          );
          response.collateralFiles = collateralFilesConverted;
          do {
            var resultConvertCollateralBindFiles = await this.convertHashToFile(
              response.collateralBindFiles[collateralBindFilesConverted.length]
            );
            collateralBindFilesConverted.push(resultConvertCollateralBindFiles);
          } while (
            collateralBindFilesConverted.length !==
            response.collateralBindFiles.length
          );
          response.collateralBindFiles = collateralBindFilesConverted;
          response = {
            ...response,
            mortgageAccountId: this.mixin_data_identifier_mortgage_account_id,
          };
          this.dataForm.step6 = response;
          this.identifier.documentChecklist.identifierEdit = true;
        } else if (resp.data.code === "DATA_NOT_FOUND") {
          this.identifier.documentChecklist.identifierEdit = false;
          this.dataForm.step6.mortgageAccountId = this.mixin_data_identifier_mortgage_account_id;
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error.response);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      }
    },
    async addMortgageDocumentChecklist() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      var confirmation = await this.simpleConfirmation(
        "Simpan Data Ini ?",
        "is-success"
      );
      if (!confirmation) {
        return;
      }
      var payload = {
        ...this.dataForm.step6,
      };
      this.uploadProgressPanel.open = true;
      var collateralFilesConverted = [];
      var collateralBindFilesConverted = [];
      do {
        var resultConvertCollateralFiles = await this.convertFileToHash(
          payload.collateralFiles[collateralFilesConverted.length]
        );
        collateralFilesConverted.push(resultConvertCollateralFiles);
      } while (
        collateralFilesConverted.length !== payload.collateralFiles.length
      );
      payload.collateralFiles = collateralFilesConverted;
      do {
        var resultConvertCollateralBindFiles = await this.convertFileToHash(
          payload.collateralBindFiles[collateralBindFilesConverted.length]
        );
        collateralBindFilesConverted.push(resultConvertCollateralBindFiles);
      } while (
        collateralBindFilesConverted.length !==
        payload.collateralBindFiles.length
      );
      payload.collateralBindFiles = collateralBindFilesConverted;
      this.uploadProgressPanel.done = true;
      this.uploadProgressPanel.uploaded = [];
      await this.simpleWait(2000);
      this.uploadProgressPanel.open = false;
      this.uploadProgressPanel.reset();
      this.property.animation.step6.submitDocumentChecklist.isLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "POST_DATA",
          endPoint: "loan",
          reqUrl: "mortgage-document-checklist",
          payload: payload,
        });
        await this.simpleWait(1500);
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        await this.simpleWait(1500);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.animation.step6.submitDocumentChecklist.isLoading = false;
      }
    },
    async editMortgageDocumentChecklist() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      var confirmation = await this.simpleConfirmation(
        "Simpan Data Ini ?",
        "is-success"
      );
      if (!confirmation) {
        return;
      }
      var payload = {
        ...this.dataForm.step6,
      };
      this.uploadProgressPanel.open = true;
      var collateralFilesConverted = [];
      var collateralBindFilesConverted = [];
      do {
        var resultConvertCollateralFiles = await this.convertFileToHash(
          payload.collateralFiles[collateralFilesConverted.length]
        );
        collateralFilesConverted.push(resultConvertCollateralFiles);
      } while (
        collateralFilesConverted.length !== payload.collateralFiles.length
      );
      payload.collateralFiles = collateralFilesConverted;
      do {
        var resultConvertCollateralBindFiles = await this.convertFileToHash(
          payload.collateralBindFiles[collateralBindFilesConverted.length]
        );
        collateralBindFilesConverted.push(resultConvertCollateralBindFiles);
      } while (
        collateralBindFilesConverted.length !==
        payload.collateralBindFiles.length
      );
      payload.collateralBindFiles = collateralBindFilesConverted;
      this.uploadProgressPanel.done = true;
      this.uploadProgressPanel.uploaded = [];
      await this.simpleWait(2000);
      this.uploadProgressPanel.open = false;
      this.uploadProgressPanel.reset();
      this.property.animation.step6.submitDocumentChecklist.isLoading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA",
          endPoint: "loan",
          reqUrl:
            "mortgage-document-checklist/mortgage-account-id/" +
            payload.mortgageAccountId,
          payload: payload,
        });
        await this.simpleWait(1500);
        if (resp.data.code === "SUCCESS") {
          this.$buefy.snackbar.open(this.$NotificationUtils.snackbarSuccess);
        } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
          this.$buefy.dialog.alert({
            title: "Harap Tunggu !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: resp.data.errorFields
              ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
              : resp.data.message,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch {
        await this.simpleWait(1500);
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? error.response.data.errorFields
              ? this.globalNotificationErrorFieldToText(
                  error.response.data.errorFields
                )
              : error.response.statusText
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
      } finally {
        this.property.animation.step6.submitDocumentChecklist.isLoading = false;
      }
    },
    resetUploadPanelProgress() {},
    convertFileToHash(payload) {
      return new Promise(async (resolve, reject) => {
        try {
          this.uploadProgressPanel.message = `Uploading : ${payload.documentFile.name}`;
          const ipfs = create(
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_DMS_STORAGE_URL
          );
          const data = payload.documentFile;
          const reader = new window.FileReader();
          reader.readAsArrayBuffer(data);
          reader.onload = async () => {
            var convertBuffer = Buffer(reader.result);
            const created = await ipfs.add(convertBuffer, {
              pin: true,
            });
            setTimeout(() => {
              this.uploadProgressPanel.message = "";
              resolve({
                ...payload,
                documentFile: created.path,
              });
              this.uploadProgressPanel.uploaded.push({
                documentFile: payload.documentFile.name,
              });
            }, 2000);
          };
        } catch (error) {
          reject(error);
        } finally {
        }
      });
    },
    convertHashToFile(payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const ipfs = create(
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_DMS_STORAGE_URL
          );
          let asyncitr = ipfs.cat(payload.documentFile);
          let buffers = [];
          for await (const itr of asyncitr) {
            buffers = [...buffers, Buffer.from(itr)];
          }
          var fileBuffer = Buffer.concat(buffers);
          const blob1 = new Blob([fileBuffer], { type: "application/json" });
          const blob = new Blob([blob1]);
          const fileType = await this.detectFileTypeFromBuffer(fileBuffer);
          const file = new File(
            [blob],
            `${payload.documentNumber}.${fileType.ext}`,
            {
              type: fileType.mime,
            }
          );
          resolve({
            ...payload,
            documentFile: file,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  mounted() {
    this.getMortgageDocumentChecklistByMortgageAccountId();
  },
};
