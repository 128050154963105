import UploadImage from "../../../../../components/upload-image";
import { create } from "ipfs-http-client";
import Mixins from "../../Mixins.js";

export default {
  name: "EditMortgagePhotoUpload",
  mixins: [Mixins],
  components: {
    UploadImage,
  },
  data() {
    return {
      dataForm: null,
      loading: false,
    };
  },
  methods: {
    onUploadIdCardFile(event) {
      const ipfs = create(
        this.fetchAppSession("@vue-session/config-bundle")
          .VUE_APP_DMS_STORAGE_URL
      );
      const data = event.imageData;
      const reader = new window.FileReader();
      reader.readAsArrayBuffer(data);
      reader.onload = async () => {
        var convertBuffer = Buffer(reader.result);
        const created = await ipfs.add(convertBuffer, {
          pin: false,
        });
        this.dataForm.idCardFile = created.path;
        this.editMortgageDocumentProof();
      };
    },
    onUploadPhotoSelfieFile(event) {
      const ipfs = create(
        this.fetchAppSession("@vue-session/config-bundle")
          .VUE_APP_DMS_STORAGE_URL
      );
      const data = event.imageData;
      const reader = new window.FileReader();
      reader.readAsArrayBuffer(data);
      reader.onload = async () => {
        var convertBuffer = Buffer(reader.result);
        const created = await ipfs.add(convertBuffer, {
          pin: false,
        });
        this.dataForm.cifFile = created.path;
        this.editMortgageDocumentProof();
      };
    },
    onUploadCollateralPreciousMetalFile(event) {
      const ipfs = create(
        this.fetchAppSession("@vue-session/config-bundle")
          .VUE_APP_DMS_STORAGE_URL
      );
      const data = event.imageData;
      const reader = new window.FileReader();
      reader.readAsArrayBuffer(data);
      reader.onload = async () => {
        var convertBuffer = Buffer(reader.result);
        const created = await ipfs.add(convertBuffer, {
          pin: false,
        });
        this.dataForm.collateralFile = created.path;
        this.editMortgageDocumentProof();
      };
    },
    onDeleteIdCardFile() {
      this.dataForm.idCardFile = null;
      this.editMortgageDocumentProof();
    },
    onDeletePhotoSelfieFile() {
      this.dataForm.cifFile = null;
      this.editMortgageDocumentProof();
    },
    onDeleteCollateralPreciousMetalFile() {
      this.dataForm.collateralFile = null;
      this.editMortgageDocumentProof();
    },
    async editMortgageDocumentProof() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      }
      try {
        const resp = await this.$store.dispatch({
          type: "PUT_DATA",
          endPoint: "loan",
          reqUrl:
            "mortgage-document-proof/files/" +
            this.mixin_data_identifier_mortgage_account_id,
          payload: {
            idCardFile: this.dataForm.idCardFile,
            collateralFile: this.dataForm.collateralFile,
            cifFile: this.dataForm.cifFile,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.$buefy.toast.open(this.$NotificationUtils.success);
            this.getMortgageDocumentProof();
            this.updateDms(resp.data.data);
          } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
            this.$buefy.dialog.alert({
              title: "Harap Tunggu !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: `${resp.data.message}`,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        }, 1500);
      } catch (error) {
        this.$buefy.dialog.alert({
          title: "Gagal !",
          message: error.response
            ? `${error.response.statusText}`
            : "Terjadi Kesalahan",
          confirmText: "Ok",
          type: "is-danger",
        });
        // eslint-disable-next-line no-empty
      } finally {
      }
    },
    async getMortgageDocumentProof() {
      this.loading = true;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_DATA_FIND_BY",
          endPoint: "loan",
          reqUrl:
            "mortgage-document-proof/" +
            this.mixin_data_identifier_mortgage_account_id,
        });
        if (resp.data.code === "SUCCESS") {
          this.dataForm = resp.data.data;
        } else {
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: `${resp.data.message}`,
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setTimeout(() => {
          this.loading = false;
        }, 1500);
      }
    },
    updateDms(data) {
      const ipfs = create(
        this.fetchAppSession("@vue-session/config-bundle")
          .VUE_APP_DMS_STORAGE_URL
      );
      if (data.oldIdCardFile !== data.idCardFile) {
        if (data.idCardFile && !data.oldIdCardFile) {
          ipfs.pin.add(`${data.idCardFile}`);
        }
        if (data.idCardFile && data.oldIdCardFile) {
          ipfs.pin.rm(`${data.oldIdCardFile}`);
          ipfs.pin.add(`${data.idCardFile}`);
        }
        if (!data.idCardFile && data.oldIdCardFile) {
          ipfs.pin.rm(`${data.oldIdCardFile}`);
        }
      }
      if (data.collateralFile !== data.oldCollateralFile) {
        if (data.collateralFile && !data.oldCollateralFile) {
          ipfs.pin.add(`${data.collateralFile}`);
        }
        if (data.collateralFile && data.oldCollateralFile) {
          ipfs.pin.rm(`${data.oldCollateralFile}`);
          ipfs.pin.add(`${data.collateralFile}`);
        }
        if (!data.collateralFile && data.oldCollateralFile) {
          ipfs.pin.rm(`${data.oldCollateralFile}`);
        }
      }
      if (data.cifFile !== data.oldCifFile) {
        if (data.cifFile && !data.oldCifFile) {
          ipfs.pin.add(`${data.cifFile}`);
        }
        if (data.cifFile && data.oldCifFile) {
          ipfs.pin.rm(`${data.oldCifFile}`);
          ipfs.pin.add(`${data.cifFile}`);
        }
        if (!data.cifFile && data.oldCifFile) {
          ipfs.pin.rm(`${data.oldCifFile}`);
        }
      }
    },
  },
  mounted() {
    this.getMortgageDocumentProof();
  },
};
